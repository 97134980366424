
import { defineComponent, ref, watch, onMounted } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampTable,
  CampTableTh,
  CampTableTd,
  CampEmptyListFeedbackV2
} from '@/components';
import { campHandleEmptyFields } from '@/composables/DataValidation';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';
import { useStoreStore } from '@/store/StoreStore';
import { useAuthStore } from '@/store/AuthStore';
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from 'moment';
import { TOptions, deleteMediaFromClient, uploadMediaFromClient } from '@/services/AzureBlobService';
import ModalQuiz, { TQuestion, createAlternative } from '@/views/Training/Components/ModalQuiz.vue';
import { useRoute } from 'vue-router';
import { getFileNameFromBlobUrl } from '../Social/ListPage.vue';
import { useFilterMainStore } from '@/store/FilterMainStore';
import MediaCardCore from '@/views/Training/Components/MediaCardCore.vue';

type TYPE_TRAINING_GROUPS_OPTIONS = {
  COMPANY: {
    KEY: 1;
    LABEL: string;
  };
  STORE: {
    KEY: 2;
    LABEL: string;
  };
  COMPETITOR: {
    KEY: 3;
    LABEL: string;
  };
};

const TRAINING_GROUPS_OPTIONS: TYPE_TRAINING_GROUPS_OPTIONS = {
  COMPANY: {
    KEY: 1,
    LABEL: "Companhia"
  },
  STORE: {
    KEY: 2,
    LABEL: "Loja"
  },
  COMPETITOR: {
    KEY: 3,
    LABEL: "Competidor"
  }
}

type TTrainingGroups = 1 | 2 | 3

type TOption = { key: string | number | null, label: string }

function initQuestion():TQuestion {
  return {
    // id: null,
    title: '',
    points: 1,
    alternatives: [ createAlternative() ]
  }
}

interface ITrainingData {
  id?: number,
  title: string,
  description: string,
  url: string | null,
  active: 0 | 1,
  points: number,
  initial_date: string,
  end_date: string
  id_user: number | string
  id_company: number[] | string[] | null,
  id_store: number[] | string[] | null,
  id_competitor: number[] | string[] | null,
}

interface ITrainingResponse {
  data: {
    error: boolean,
    message: string,
    data: any
    errorCode: string
  },
  status: number
}

interface ITrainingQuestionResponse {
  data: {
    error: boolean,
    message: string,
    data: any
    errorCode: string
  },
  status: number
}

interface ICreateTrainingResponse {
  data: {
    error: boolean,
    message: string,
    data: any
    errorCode: string
  },
  status: number
}

export interface IMediaFile {
  file: File | null
  URL: string | null
  youtubeURL: string | null
  contentType: string
  blobName: string
  URLFromParent: string | null
}

export const initMediaFile = {
  URL: null,
  youtubeURL: null,
  file: null,
  contentType: "",
  blobName: "",
  URLFromParent: ""
}

export default defineComponent({
  name: "TrainingPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    ModalQuiz,
    CampTable,
    CampTableTh,
    CampTableTd,
    CampEmptyListFeedbackV2,
    MediaCardCore
  },
  setup() {
    // Variables
    const filterMainStore = useFilterMainStore()
    const isLoading = ref(true)
    const route = useRoute()
    const idRoute = route.params.id
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isRequiredField = ref(false)
    const infoMaxCharacters = ref<string | null>(null)
    const infoMaxCharactersWarning = ref<string | null>(null)
    const quizModalToggle = ref(false)
    /** Company */
    const companyStore = useCompanyStore()
    /** Store */
    const storeStore = useStoreStore()
    /** User */
    const authStore = useAuthStore()
    /** Training */
    const activeStatus = ref(false)
    const trainingGroupsId = ref<TTrainingGroups>(authStore.isAdmUser ? TRAINING_GROUPS_OPTIONS.COMPANY.KEY : TRAINING_GROUPS_OPTIONS.STORE.KEY)
    const trainingGroupsList = [
      { key: TRAINING_GROUPS_OPTIONS.STORE.KEY, label: TRAINING_GROUPS_OPTIONS.STORE.LABEL },
      { key: TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY, label: TRAINING_GROUPS_OPTIONS.COMPETITOR.LABEL }
    ]
    const trainingGroupsOptions = ref(authStore.isAdmUser ? [ { key: TRAINING_GROUPS_OPTIONS.COMPANY.KEY, label: TRAINING_GROUPS_OPTIONS.COMPANY.LABEL }, ...trainingGroupsList ] : [ ...trainingGroupsList ])
    const companyOptions = ref<TOption[]>([ { key: "", label: "" } ])
    const storeOptions: typeof companyOptions = ref([{ key: "", label: "" }])
    const competitorOptions: typeof companyOptions = ref([{ key: "", label: "" }])
    const currentDate = new Date()
    const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
    const mediaFile = ref<IMediaFile>({ ...initMediaFile })
    const INIT_DATA: ITrainingData = {
      title: "",
      points: 10,
      description: "",
      url: "",
      initial_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      active: activeStatus.value ? 1 : 0,
      id_company: null,
      id_store: null,
      id_competitor: null,
      id_user: authStore.getUser ? authStore.getUser.id : ""
    }
    const formModel = ref<ITrainingData>(INIT_DATA)
    const formModelAux = ref<ITrainingData>({
        title: '',
        description: '',
        url: null,
        active: 0,
        points: 0,
        initial_date: '',
        end_date: '',
        id_user: '',
        id_company: null,
        id_store: null,
        id_competitor: null
    })
    const urlMediaBackup = ref<string | null>(null)
    /** Question */
    const question = ref<TQuestion >(initQuestion())
    const questionList = ref<TQuestion[]>([])
    const editQuestionId = ref(-1)

    // Functions
    async function getTrainingView() {
      loaderStore.open()
      try {
        const [response1, response2, response3] = await Promise.all([
          axios.get(`/api/getTrainingView?id=${idRoute}`),
          axios.get(`/api/getTrainingQuestionsList?id_training=${idRoute}`),
          axios.get(`/api/getTrainingGroupsByIdTraining?id_training=${idRoute}`)
        ]);

        const { active, description, id_company, id_store, id_competitor, title, url, initial_date, end_date, id_user, id, points } = response1.data.data
        urlMediaBackup.value = url
        formModel.value = {
          id,
          active,
          description,
          title,
          url,
          initial_date,
          end_date,
          id_user,
          points,
          id_company: null,
          id_store,
          id_competitor,
        }
        activeStatus.value = active ? true : false
        mediaFile.value.URLFromParent = url ? url : ""

        questionList.value = response2.data.data.map((el: TQuestion) => ({
          id: el.id,
          title: el.title,
          points: el.points,
          alternatives: el.alternatives.map(el => ({ ...el, del: false })),
          del: false
        }))

        const { data } = response3.data
        if(data.some(el => el.id_company)) {
          trainingGroupsId.value = TRAINING_GROUPS_OPTIONS.COMPANY.KEY
          formModelAux.value.id_company = data.map(el => el.id_company)
        } else if(data.some(el => el.id_store)) {
          trainingGroupsId.value = TRAINING_GROUPS_OPTIONS.STORE.KEY
          formModelAux.value.id_store = data.map(el => el.id_store)
        } else {
          trainingGroupsId.value = TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY
          try {
            const response = await axios.get(`/api/getCompetitorTrainingListByIdTraining?id_training=${formModel.value.id}`)
            const { data } = response.data
            formModelAux.value.id_competitor = data.map(el => el.id_competitor)
          } catch (error) {
            if(error instanceof AxiosError) {
              if(error.response)
                showTimeAlert(error.response?.data.message, "error")
              else
                showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
            } else {
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
            }
          }
        }
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      } finally {
        loaderStore.close()
      }
    }

    async function getTrainingGroups() {
      isLoading.value = true
      try {
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPANY.KEY) {
          const response = await axios.get(`/api/getCompanyList`)
          const { data } = response.data
          companyOptions.value = [ ...data.map(el => ({ key: el.id, label: el.fantasy_name })) ]
          if(Array.isArray(formModelAux.value.id_company) && formModelAux.value.id_company.length) {
            formModelAux.value.id_company.forEach((id: string | number | null) => {
              const ID = +id!
              companyOptions.value = companyOptions.value.filter(company => company.key !== ID)
            })
          }
        }
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.STORE.KEY) {
          const response = await axios.get(`/api/getStoreList`)
          const { data } = response.data
          storeOptions.value = [ ...data.map(el => ({ key: el.id, label: el.fantasy_name + ' - ' + el.cnpj })) ]
          if(Array.isArray(formModelAux.value.id_store) && formModelAux.value.id_store.length) {
            formModelAux.value.id_store.forEach((id: string | number | null) => {
              const ID = +id!
              storeOptions.value = storeOptions.value.filter(store => store.key !== ID)
            })
          }
        }
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY) {
          const response = await axios.get(`/api/getCompetitor`)
          const { data } = response.data
          competitorOptions.value = [ ...data.map(el => ({ key: el.id, label: el.name })) ]
          if(Array.isArray(formModelAux.value.id_competitor) && formModelAux.value.id_competitor) {
            formModelAux.value.id_competitor.forEach((id: string | number | null) => {
              const ID = id!.toString()
              competitorOptions.value = competitorOptions.value.filter(competitor => competitor.key !== ID)
            })
          }
        }
        isLoading.value = false
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      }
    }

    const resetWarning = () => isRequiredField.value = false

    /** Date */
    const handleDisabledDateMin = (time: Date) => time.getTime() < maxDate.getTime()

    const handleDisabledDateMax = (time: Date) => time.getTime() < moment(formModel.value.initial_date).valueOf()

    const handleFixRangeDate = () => {
      if(moment(formModel.value.initial_date).valueOf() >= moment(formModel.value.end_date).valueOf())
        formModel.value.end_date = moment(formModel.value.initial_date).add(1, 'hour').format()
      if(!formModel.value.initial_date || !formModel.value.end_date) {
        formModel.value.initial_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        formModel.value.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    }

    /** Size Desc */
    function handleSizeDesc(max = 255) {
      const length = formModel.value.description.length
      infoMaxCharacters.value = `${length}/${max}`
      if(length > max)
        infoMaxCharactersWarning.value = `Máximo de ${max} caracteres atingido!`
      else
        infoMaxCharactersWarning.value = null
    }

    /** Handle Open Media Modal */
    const handleQuizModalToggle = () => quizModalToggle.value = !quizModalToggle.value

    const resetQuizModal = () => {
      question.value = initQuestion()
      editQuestionId.value = -1
    }

    const handleQuestion = (value: { question: TQuestion, editQuestionId: number }) => {
      if(value.editQuestionId < 0) {
        questionList.value.push(value.question)
      }
      else {
        questionList.value[value.editQuestionId] = value.question
        resetQuizModal()
      }
    }

    const handleEditQuestion = (edit: TQuestion, id: number) => {
      question.value = edit
      editQuestionId.value = id
      handleQuizModalToggle()
    }

    const handleRemoveQuestion = (i: number) => {
      resetQuizModal()
      questionList.value = questionList.value.map((el, j) => (i !== j ? { ...el } : { ...el, del: true }))
    }

    const handleUndoRemoveQuestion = (i: number) => {
      resetQuizModal()
      questionList.value = questionList.value.map((el, j) => (i !== j ? { ...el } : { ...el, del: false }))
    }

    async function handleCompetitorTraining(id_training: number) {
      let id_company: number[] | string[] | null = null
      let id_store: number[] | string[] | null = null
      let id_competitor: number[] | string[] | null = null

      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPANY.KEY)
        id_company = formModel.value.id_company

      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.STORE.KEY)
        id_store = formModel.value.id_store
      
      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY)
        id_competitor = formModel.value.id_competitor

      const bodyToPost = {
        id_training,
        id_company,
        id_store,
        id_competitor
      }

      if(
        (id_company && Array.isArray(id_company) && id_company.length)
        ||
        (id_store && Array.isArray(id_store) && id_store.length)
        ||
        (id_competitor && Array.isArray(id_competitor) && id_competitor.length)
      ) {
        try {
          const response: ICreateTrainingResponse = await axios.post(`/api/postCompetitorTraining`, bodyToPost)
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response)
              showTimeAlert(error.response?.data.message, "error")
            else
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        }
      }
    }

    const helperDelFileClowd = async () => {
      if(typeof urlMediaBackup.value !== "string")
        return
      const url = getFileNameFromBlobUrl(urlMediaBackup.value)
      const lastIndex = url.lastIndexOf("/");
      const blobName = url.substring(lastIndex + 1);

      let folder = ""
      if(mediaFile.value.contentType.startsWith('image/'))
        folder = "image/"
      if(mediaFile.value.contentType.startsWith('video/'))
        folder = "video/"
      if(mediaFile.value.contentType.startsWith('application/pdf'))
        folder = "application/"

      await deleteMediaFromClient(`training/${folder}${blobName}`)
    }

    async function onSubmitForm() {
      isRequiredField.value = false

      if(infoMaxCharactersWarning.value){
        showTimeAlert("Verifique os campos obrigatórios.")
        return true
      }

      formModel.value.initial_date = moment(formModel.value.initial_date).format('YYYY-MM-DD HH:mm:ss')
      formModel.value.end_date = moment(formModel.value.end_date).format('YYYY-MM-DD HH:mm:ss')
      formModel.value.active = activeStatus.value ? 1 : 0
      const { id_company, id_store, id_competitor, id, url, ...requiredFields } = formModel.value

      if(campHandleEmptyFields(requiredFields)) {
        showTimeAlert("Verifique os campos obrigatórios.")
        isRequiredField.value = true
        return true
      }

      const bodyToUpdate = {
        url,
        id_company,
        id_training: idRoute,
        ...requiredFields,
        questionList: questionList.value
      }

      if(mediaFile.value.file) {
        const options: TOptions = {
          blobHTTPHeaders: {
            blobContentType: mediaFile.value.contentType
          }
        };
        try {
          loaderStore.open()
          const { request } = (await uploadMediaFromClient(mediaFile.value.file, mediaFile.value.blobName, options))._response
          bodyToUpdate.url = request.url
          helperDelFileClowd()
        } catch (error) {
          loaderStore.close()
          return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
        }
      }

      if(!mediaFile.value.URL) {
        try {
          loaderStore.open()
          helperDelFileClowd()
        } catch (error) {
          loaderStore.close()
          return showTimeAlert('Erro ao excluir media, tente mais tarde!', 'error')
        }
      }

      /** Exclui URL no Banco do Blob removido na Nuvem */
      if(!mediaFile.value.URL)
        bodyToUpdate.url = null

      /** Sobrepõe URL antiga para atualizar */
      if(mediaFile.value.youtubeURL)
        bodyToUpdate.url = mediaFile.value.youtubeURL

      try {
        loaderStore.open()
        const response: ITrainingResponse = await axios.put(`/api/putTraining`, { ...bodyToUpdate })
        const idTraining = +(Array.isArray(bodyToUpdate.id_training) ? bodyToUpdate.id_training[0] : bodyToUpdate.id_training)
        handleCompetitorTraining(idTraining)
        showTimeAlert("Treinamento atualizado com sucesso!")
        // formModel.value = { ...INIT_DATA }
        router.push("/treinamentos")
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          }else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
        loaderStore.close()
      }
    }

    const handleUpdatedMediaFile = (emittedObject: IMediaFile) => mediaFile.value = emittedObject

    // /** Handle Filter Modal */
    // function handleInitFilterModal() {
    //   if(!companyStore.getId || !storeStore.getId) {
    //     filterMainStore.hideModal()
    //     filterMainStore.showModal()
    //   }
    // }

    // Life cycles - Actions
    onMounted(async() => {
      // handleInitFilterModal()
      await getTrainingView()
      await getTrainingGroups()
    })

    watch(() => { trainingGroupsId.value, companyStore.getId }, async () => await getTrainingGroups(), { deep: true })

    watch(() => formModel.value.description, () => handleSizeDesc())

    watch(() => { formModel.value.initial_date, formModel.value.end_date }, () => handleFixRangeDate(), { deep: true })

    return {
      isRequiredField,
      isLoading,
      companyStore,
      storeStore,
      resetWarning,
      formModel,
      infoMaxCharacters,
      infoMaxCharactersWarning,
      trainingGroupsId,
      trainingGroupsOptions,
      activeStatus,
      ptBr,
      TRAINING_GROUPS_OPTIONS,
      companyOptions,
      storeOptions,
      competitorOptions,
      mediaFile,
      handleUpdatedMediaFile,
      handleDisabledDateMin,
      handleDisabledDateMax,
      onSubmitForm,
      handleQuizModalToggle,
      quizModalToggle,
      question,
      questionList,
      editQuestionId,
      resetQuizModal,
      handleQuestion,
      handleEditQuestion,
      handleRemoveQuestion,
      handleUndoRemoveQuestion,
      moment,
    }
  }
})
