import { defineStore } from "pinia";

interface IPostStore {
  dateRange: string[] | string;
  startDate: string;
  endDate: string;
}

export const usePostStore = defineStore("PostStore", {
  state: (): IPostStore => {
    return {
      dateRange: "",
      startDate: "",
      endDate: ""
    }
  },
  actions: {
    setDateRange(dateRange: string[] | string) {
      this.dateRange = dateRange

      if(Array.isArray(dateRange) && dateRange.length == 2) {
        this.startDate = dateRange[0]
        this.endDate = dateRange[1]
      } else {
        this.startDate = ""
        this.endDate = ""
      }

      localStorage.setItem("rangePost", dateRange ? dateRange.toString() : "")
    }
  },
  getters: {
    getDateRange(): string[] | string {
      return this.dateRange
    },
    getStartDate(): string {
      return this.startDate
    },
    getEndDate(): string {
      return this.endDate
    }
  }
});