
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, onBeforeMount, Ref } from 'vue';
import moment from 'moment';
import modalFilterSenderPosts from "./Components/modalFilterSenderPost.vue"
import { Modal } from "bootstrap";
import { usePostStore } from '@/store/PostStore';
import CampEmptyListFeedbackV2 from '@/components/CampEmptyListFeedbackV2.vue'
import useAlert from "@/composables/Alert";
import { CampDropdown, CampDropdownHeader, CampDropdownItem } from '@/components';
import { deleteMediaFromClient } from '@/services/AzureBlobService';

interface IPostResponse {
    created_at: boolean,
    id: number,
    id_user: number,
    id_company: number,
    id_store: number,
    message: string,
    priority: string,
    send_date: string,
    title: string,
    type: string,
    updated_at: string,
    url_media: string | null,
    url_avatar: string | null,
    send_to: string,
    description: string,
    count: number,
    user: any
}

export function getFileNameFromBlobUrl(blobUrl: string) {
  const index = blobUrl.indexOf("?");
  if (index === -1) {
    return blobUrl;
  }

  return blobUrl.substring(0, index);
}

export function getExtensionFromFileName(fileName: string) {
  const index = fileName.lastIndexOf(".");
  if (index === -1) {
    return "";
  }

  return fileName.substring(index + 1);
}

function replaceLastCommaWithValue(list: string[], format: 'full' | 'part' = "part", max = 30, value = " e"): string {
  const sequence = list.join(", ")
  if(sequence.length >= max && format === "part")
    return sequence.substring(0, max) + "..."

  const lastIndex = sequence.lastIndexOf(",");

  if (lastIndex !== -1) {
    const updatedSequence =
      sequence.slice(0, lastIndex) + value + sequence.slice(lastIndex + 1);
    return updatedSequence;
  }
  
  return sequence;
}

export default defineComponent({
  name: "PostList",
  components: {
    modalFilterSenderPosts,
    CampEmptyListFeedbackV2,
    CampDropdown,
    CampDropdownHeader,
    CampDropdownItem
  },
  setup() {
    const posts = ref<IPostResponse[] | null>([])
    const filterDom = ref("")
    const whatchProps = ref(false)
    const postStore = usePostStore()
    const loading = ref(false)
    const count: Ref<number> = ref(1)
    const postSelected = ref<any>()
    const postDeleted = ref<any>()

    const auxModal:any = ref(null);
    const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };
      
    function closeModal() {
          auxModal.value.hide();
        };

    function closeModalTeste() {
      auxModal.value.hide();
      auxModal.value = null;
    };

    const { showTimeAlert } = useAlert()

    function makePlural(string: string, verify: number) {
      if(string == "Todos") {
        return string
      }
      if(verify > 1) {
        if(string == 'Vendedor') {
          return 'vendedores'
        } else {
          return string + 's'
        }
      } else {
        return string
      }
    }
    async function getPosts() {
      loading.value = true
      let url = '/api/getPostsUser'
      if(postStore.getStartDate && postStore.getEndDate) {
        url = `${url}?start_date=${moment(postStore.getStartDate).format('DD-MM-YYYY')}&end_date=${moment(postStore.getEndDate).format('DD-MM-YYYY')}`
      }
      try {
        const { data } = await axios.get(url)
        posts.value = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }

      loading.value = false
    }

    async function deletePost(id) {
      loading.value = true
      let url = `/api/deletePost?id=${id}`
      try {
        try{
          if(postDeleted.value.url_media) {
            const regex = /pontuei\/([^?]+)/;
            const match = postDeleted.value.url_media.match(regex);
            if(match) await deleteMediaFromClient(match[1])
          }
        }catch (error) {}
        await axios.delete(url)
        getPosts()
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
      postDeleted.value = null
      loading.value = false
    }

    function setFormatDate(date) {
      const newdate = new Date(date);
      const brazilianDate = newdate.toLocaleDateString("pt-BR");
      const brazilianTime = newdate.toLocaleTimeString("pt-BR")
      return brazilianDate + " - " + brazilianTime.substring(0, 5)
    }

    function openModalSelectCompetitor(edit = false, post: any = null) {
      if(!edit) postSelected.value = null
      else {
        postSelected.value = post
        postSelected.value.uniqueId = Date.now()
      } 
      whatchProps.value = !whatchProps.value
      count.value++
      openModal("ModalSelectCompetitor")
    }

    /* Local Storage */
    function getDataLocalStorage() {
      const rangePost = localStorage.getItem("rangePost")
      if(rangePost) {
        postStore.setDateRange(rangePost.split(","))
      } else {
        postStore.setDateRange('')
      }
    }

    watch(() => { postStore.getStartDate, postStore.getEndDate }, () => {
      getPosts()
    }, {deep: true})
    onBeforeMount(() => getDataLocalStorage())
    onMounted(getPosts)
    return {
      posts,
      moment,
      setFormatDate,
      filterDom,
      openModalSelectCompetitor,
      whatchProps,
      getPosts,
      auxModal,
      closeModal,
      postStore,
      loading,
      getFileNameFromBlobUrl,
      getExtensionFromFileName,
      replaceLastCommaWithValue,
      makePlural,
      count,
      closeModalTeste,
      deletePost,
      postSelected,
      postDeleted
    }
  }
})
