
import { defineComponent, onMounted, ref, watch } from "vue";
import ModalYoutubeURL from '@/views/Training/Components/ModalYoutubeURL.vue';
import useAlert from "@/composables/Alert";
import moment from "moment";
import { getFileContentType } from "@/composables/DataValidation";
import { IMediaFile } from "../PutPage.vue";
import { useLoaderStore } from "@/store/LoaderStore";
import { CampModalMediaFromURL } from '@/components';

export default defineComponent({
    name: "MediaCardCore",
    components: {
        ModalYoutubeURL,
        CampModalMediaFromURL
    },
    emits: ['get:UpdatedMediaFile'],
    props: {
        mediaFile: {
            type: Object as () => IMediaFile,
            required: true
        },
        updateMode: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        /** Variables */
        const loaderStore = useLoaderStore()
        const { showTimeAlert } = useAlert()
        const mediaFile = ref<IMediaFile>({ ...props.mediaFile })
        const mediaFileURL = ref<string | null>(null)
        const mediaFileAuxURL = ref<string | null>(null)
        const numberVideosLoaded = ref(0)
        const isVideoMedia = ref(false)
        const isImageMedia = ref(false)
        const isApplicationMedia = ref(false)
        const youtubeURLModalToggle = ref(true)
        const viewModalToggle = ref(true)
        const isLoadingMedia = ref(props.updateMode)
        const btnEyeDisabled = ref(true)

        /** Functions */
        const resetStates = () => {
            mediaFile.value = { ...props.mediaFile }
            mediaFileURL.value = null
            mediaFileAuxURL.value = null
            isVideoMedia.value = false
            isImageMedia.value = false
            isApplicationMedia.value = false
            btnEyeDisabled.value = true
        }

        const stopLoaderMedia = () => isLoadingMedia.value = false

        const checkMediaSize = (contentType: string) => {
            if(!mediaFile.value.file)
                return true

            const KB = 10**3
            const MB = 1000*KB
            const GB = 1000*MB

            let maxSizeInfo = 0
            const imageMaxSize = 2*MB
            const pdfMaxSize = 2.5*MB
            const videoMaxSize = 4*GB

            const fileSize = mediaFile.value.file.size

            if(contentType === "image" && fileSize > imageMaxSize)
                maxSizeInfo = imageMaxSize

            if(contentType === "application/pdf" && fileSize > pdfMaxSize)
                maxSizeInfo = pdfMaxSize

            if(contentType === "video" && fileSize > videoMaxSize)
                maxSizeInfo = videoMaxSize

            if(maxSizeInfo) {
                resetStates()
                stopLoaderMedia()
                const msg1 = fileSize < GB ? `${(fileSize / MB).toFixed(1)} Megabyte(s)` : `${(fileSize / GB).toFixed(1)} Gigabyte(s).`
                const msg2 = maxSizeInfo < GB ? `${(maxSizeInfo / MB).toFixed(1)} Megabyte(s)` : `${(maxSizeInfo / GB).toFixed(1)} Gigabyte(s).`
                showTimeAlert(`Tamanho do arquivo ${msg1}. Tamanho máximo suportado ${msg2}`, "error")
                return true
            }

            return false
        }

        async function refreshCardCore() {
            btnEyeDisabled.value = true
            isLoadingMedia.value = true
            mediaFile.value = { ...props.mediaFile }
            if (mediaFile.value.URLFromParent && mediaFile.value.URLFromParent.includes("youtube")) {
                mediaFile.value.youtubeURL = mediaFile.value.URLFromParent
            } else {
                mediaFileAuxURL.value = mediaFile.value.URLFromParent

                mediaFile.value.contentType = await getFileContentType(mediaFileAuxURL.value || "") || ""

                if(mediaFile.value.contentType.startsWith('image/')) {
                    btnEyeDisabled.value = false
                    isImageMedia.value = true
                }

                if(mediaFile.value.contentType.startsWith('video/'))
                    isVideoMedia.value = true

                if(mediaFile.value.contentType.startsWith('application/pdf')) {
                    btnEyeDisabled.value = false
                    isApplicationMedia.value = true
                    stopLoaderMedia()
                }
            }
        }

        function handleMediaFileChange(event: Event) {
            btnEyeDisabled.value = true
            isLoadingMedia.value = true
            isVideoMedia.value = false
            isImageMedia.value = false
            isApplicationMedia.value = false
            mediaFile.value.youtubeURL = null
            const fileInput = event.target as HTMLInputElement
            const file: File | null | undefined = fileInput.files?.[0]
            let error = false

            if(!file)
                return

            mediaFile.value.file = file
            mediaFile.value.contentType = file.type
            let folder = ""

            if (mediaFile.value.contentType.startsWith('image/')) {
                btnEyeDisabled.value = false
                folder = "image/"
                isImageMedia.value = true
                error = checkMediaSize('image')
            } else if (mediaFile.value.contentType.startsWith('video/')) {
                folder = "video/"
                isVideoMedia.value = true
                numberVideosLoaded.value += 1
                error = checkMediaSize('video')
            } else if (mediaFile.value.contentType.startsWith('application/pdf')) {
                btnEyeDisabled.value = false
                folder = "application/"
                stopLoaderMedia()
                isApplicationMedia.value = true
                error = checkMediaSize('application/pdf')
            } else {
                resetStates()
                stopLoaderMedia()
                showTimeAlert("Suporte apenas para vídeos, imagens e pdf", "error")
                error = true
            }

            if(!error) {
                mediaFileAuxURL.value = URL.createObjectURL(mediaFile.value.file)
                mediaFile.value.blobName = `training/${folder}${moment().valueOf()}.${mediaFile.value.file.name.split(".")[1]}`
            }

            fileInput.value = '';
        }

        const handleMediaDel = () => {
            resetStates()
            mediaFile.value.youtubeURL = null
        }

        const handleMediaFileExchangeInitialStep = () => {
            mediaFileURL.value = mediaFileURL.value ? null : mediaFileAuxURL.value
        }

        const handleMediaFileExchangeEndStep = () => {
            if(!mediaFileURL.value && mediaFile.value.youtubeURL)
                return
            mediaFile.value.youtubeURL = null
            mediaFileURL.value = mediaFileAuxURL.value
            mediaFile.value.URL = mediaFileAuxURL.value
        }

        const updateMediaFile = () => emit("get:UpdatedMediaFile", <IMediaFile>{ ...mediaFile.value })

        const handleYoutubeURL = (url: string) => {
            btnEyeDisabled.value = true
            mediaFileURL.value = null
            isLoadingMedia.value = true
            mediaFile.value.youtubeURL = url
        }

        /** Life cycles - Actions */
        watch(() => props.mediaFile.URLFromParent, () => refreshCardCore())

        watch(() => mediaFileAuxURL.value, () => handleMediaFileExchangeInitialStep())

        watch(() => mediaFileURL.value, () => handleMediaFileExchangeEndStep())

        watch(() => mediaFile.value, () => updateMediaFile(), { deep: true })

        onMounted(() => props.updateMode && loaderStore.open())

        return {
            loaderStore,
            isLoadingMedia,
            stopLoaderMedia,
            youtubeURLModalToggle,
            viewModalToggle,
            mediaFile,
            mediaFileURL,
            numberVideosLoaded,
            handleMediaDel,
            handleMediaFileChange,
            isImageMedia,
            isVideoMedia,
            isApplicationMedia,
            handleYoutubeURL,
            btnEyeDisabled
        }
    }
})
