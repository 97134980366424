
import { defineComponent, watch, ref } from "vue";
import { Modal } from "bootstrap";
import { CampFormRackSubmitBtn } from '@/components';
import {
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';

function obterIdDoVideo(url:string) {
  // Expressão regular para extrair o ID do vídeo da URL do YouTube
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Testa a URL com a expressão regular
  const correspondencia = url.match(regex);

  // Se houver uma correspondência, retorna o ID do vídeo, caso contrário, retorna null
  return correspondencia ? correspondencia[1] : null;
}

export default defineComponent({
  name: "ModalQuiz",
  components: {
    CampFormRackSubmitBtn,
    CampTable,
    CampTableTd,
    CampTableTh
  },
  emits: ['get:YoutubeURL'],
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    youtubeURL: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const header = ref("Insira o link do vídeo abaixo")
    const UUID = "91470d4c-3a56-47a4-ac0b-5d5d89793aa4"
    const youtubeURL = ref(props.youtubeURL)

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    function handleYoutubeURL() {
      youtubeURL.value && emit("get:YoutubeURL", `https://www.youtube.com/embed/${obterIdDoVideo(youtubeURL.value)}`)
    }

    // User actions - Life Cycles
    watch(() => props.toggle, () => openModal(`modal-youtube-url-${UUID}`))

    return {
      header,
      UUID,
      handleYoutubeURL,
      youtubeURL,
    }
  }
})
