
    import { defineComponent, onMounted, ref, watch, computed } from "vue";
    import axios, { AxiosError } from 'axios';
    import { useAuthStore } from "@/store/AuthStore";
    import useAlert from "@/composables/Alert";
    import { useLoaderStore } from "@/store/LoaderStore"
    import { Modal } from "bootstrap";
    import  ModalCropImage from "./ModalCropImage.vue"
    import imgDefalt from "@/views/Social/Components/assets/undefined-img.png"
    import moment from "moment";
    import { ElConfigProvider } from "element-plus";
    import ptBr from "element-plus/lib/locale/lang/pt-br";
    import { TOptions, uploadMediaFromClient, deleteMediaFromClient } from '@/services/AzureBlobService';
    import { CampTiptapEditor } from '@/components';
    import { useEditor } from '@tiptap/vue-3'
    import StarterKit from '@tiptap/starter-kit'
    import Placeholder from '@tiptap/extension-placeholder'
    import Underline from '@tiptap/extension-underline'
    import Link from '@tiptap/extension-link'
    import { onBeforeMount } from "vue";


    interface IPostNotification {
        message: string,
        type: string,
        url_media: string,
        priority: string,
        send_date: string,
        send_to: string
    }

    type TYPE_SENDER_OPTIONS = {
      COMPANY: {
        KEY: number;
        LABEL: string;
      };
      STORE: {
        KEY: number;
        LABEL: string;
      };
    };

    type TSender = { key: number | string, label: string }

    function truncateFileName(fileName: string, limit = 10): string {
      if (fileName.length <= limit) {
        return fileName;
      } else {
        const extension = fileName.substring(fileName.lastIndexOf('.'));
        const truncatedName = fileName.substring(0, limit - extension.length) + '... ' + extension;
        return truncatedName;
      }
    }

    export default defineComponent({
        name: "modalFilterSenderPost",
        emits: [ "getPosts", "closeModal", "closeModalTeste" ],
        props:{
            relationData: Object,
            isChangeProps: Boolean,
            closeModal: HTMLElement,
            closeModalTeste: Function,
            count: Number,
            postSelectedEdit: Object

        },
        components: {
          ModalCropImage,
          ElConfigProvider,
          CampTiptapEditor
        },
        setup(props, {emit}) {
            const editor = useEditor({
              content: '',
              extensions: [
                StarterKit,
                Placeholder.configure({
                  placeholder: 'Escreva a mensagem...',
                  emptyNodeClass: 'my-custom-is-empty-class',
                }),
                Underline,
                Link.configure({
                  openOnClick: true,
                }),
              ],
            })

            const userLevel = ref()
            const switNumberStep = ref(1)
            const modalCompanyShow = ref(false)
            const companies = ref()
            const loading = ref() 
            const userStore = useAuthStore()
            const entityFilter = ref<any>([])
            const isAlltrue = ref(false)
            const filterDom = ref('')
            const sendTo = ref('')
            const config = ref({
              type: "",
              priority: "",
              date: "",
              time: ""
            })
            const send = ref({
              media: "" as any,
              mediaName: "Selecione uma mídia:",
              message: "",
              imgToCrop: "" as any
            })
            const sendAll = ref(false)
            const { showTimeAlert } = useAlert()
            const loaderStore = useLoaderStore()

            const SENDER_OPTIONS: TYPE_SENDER_OPTIONS = {
              COMPANY: {
                KEY: 1,
                LABEL: "Companhia"
              },
              STORE: {
                KEY: 2,
                LABEL: "Loja"
              }
            }
            const senderId = ref(0)
            const senderList = ref<TSender[] | null>(
              userStore.isAdmUser ?
              [
                { key: 0, label: userStore.getUser.name },
                { key: SENDER_OPTIONS.COMPANY.KEY, label: SENDER_OPTIONS.COMPANY.LABEL },
                { key: SENDER_OPTIONS.STORE.KEY, label: SENDER_OPTIONS.STORE.LABEL }
              ]
              :
              [
                { key: 0, label: userStore.getUser.name },
                { key: SENDER_OPTIONS.STORE.KEY, label: SENDER_OPTIONS.STORE.LABEL }
              ]
            )
            const senderCompanyId = ref<number | string>("")
            const senderCompanyList = ref<TSender[] | null>(null)
            const senderStoreId = ref<number | string>("")
            const senderStoreList = ref<TSender[] | null>(null)
            const senderForwardBtnDisabled = ref(true)
            const senderDesc = ref("")

            const mediaFile = ref<File | null>(null)
            const mediaFileURL = ref("")
            const contentType = ref("")
            const isImageFile = ref(false)
            const isVideoFile = ref(false)
            const blobName = ref("")

            const totalVideoUpload = ref(0)
            const idJob = ref<number | null>(null)
            const jobName = ref()
            const idPost = ref(0)
            const postEditMedia = ref('')

            const combinedDateTime = computed(() => {
              if (config.value.date && config.value.time) {
                return `${config.value.date} ${config.value.time}`;
             }
              return null;
            });

            function disabledDateInNotification(time: Date) {
              return time.getTime() < Date.now() - 86400000;
            }

            async function resetValues() {
              entityFilter.value = []
              isAlltrue.value = false
              send.value.message = ""
              send.value.media = ""
              send.value.mediaName = "Selecione uma mídia:"
              config.value.type = ""
              config.value.date = ""
              config.value.time = ""
              config.value.priority = ""
              sendTo.value = ""
              switNumberStep.value = 1
              modalCompanyShow.value = false
              senderId.value = 0
              senderCompanyId.value = ""
              senderStoreId.value = ""
              sendAll.value = false
              idJob.value = null
              idPost.value = 0
              mediaFile.value = null
              mediaFileURL.value = ''
              editor.value?.commands.clearContent(true)
              return
            }

            async function resetEditorValues() {
              const $element = document.querySelector(".camp-tiptap-editor-body > div")
              if(!$element) return
              while($element.firstChild){
                $element.removeChild($element.firstChild);
              }
              return
            }

            function selectAll() {
              resetValues()
              modalCompanyShow.value = true
              switNumberStep.value = 2
              sendAll.value = true
            }

            async function selectCompany() {
              loading.value = true
              resetValues()
              try {
                const response = await axios.get('/api/getCompanyList')
                companies.value = response.data.data.map(elem => {
                  return {company: elem, checked: false}
                })

                sendTo.value = "Companhia"
              } catch (error) {

              } finally {
                loading.value = false
              }
              modalCompanyShow.value = true
            }

            async function selectStore() {
              resetValues()
              loading.value = true
              try {
                const response = await axios.get('/api/getStoreList')
                companies.value = response.data.data.map(elem => {
                  return {company: elem, checked: false}
                })
                sendTo.value = 'Loja'
              } catch (error) {
                
              } finally {
                loading.value = false
              }
              modalCompanyShow.value = true
            }

            async function selectCompetitor() {
              resetValues()
              loading.value = true
              try {
                const response = await axios.get('/api/getCompetitorList')
                companies.value = response.data.data.map(elem => {
                  return {company: elem, checked: false}
                })
                sendTo.value = 'Vendedor'
              } catch (error) {
                
              } finally {
                loading.value = false
              }
              modalCompanyShow.value = true
            }

            function setEntity(entity) {
                entity.checked = !entity.checked
            }

            function backSwitch() {
              modalCompanyShow.value = false
              switNumberStep.value > 1 ? switNumberStep.value = switNumberStep.value - 1: switNumberStep.value
            }

            async function handleSenderList(senderId: number) {
              try {
                if(senderId === SENDER_OPTIONS.COMPANY.KEY && senderCompanyList.value === null) {
                  const { data } = (await axios.get(`/api/getCompanyList`)).data
                  senderCompanyList.value = [ { key: "", label: "Selecione uma Companhia" }, ...data.map(el => ({ key: el.id, label: el.fantasy_name }))]
                }
                if(senderId === SENDER_OPTIONS.STORE.KEY && senderStoreList.value === null) {
                  const { data } = (await axios.get(`/api/getStoreList`)).data
                  senderStoreList.value = [ { key: "", label: "Selecione uma Loja" }, ...data.map(el => ({ key: el.id, label: el.fantasy_name }))]
                }
              } catch (error) {
                if(error instanceof AxiosError) {
                    showTimeAlert(error.response?.data.message, "error")
                } else {
                  showTimeAlert("Algo deu errado!", "error")
                }
              }
            }

            function hanldeSenderForwardBtn() {
              senderForwardBtnDisabled.value = true
              if(senderId.value === 0) {
                senderForwardBtnDisabled.value = false
                senderDesc.value = userStore.getUser.name
              }
              if(SENDER_OPTIONS.COMPANY.KEY === senderId.value && senderCompanyId.value) {
                senderForwardBtnDisabled.value = false
                if(Array.isArray(senderCompanyList.value)) {
                  senderDesc.value = `${(senderCompanyList.value.find(el => el.key === senderCompanyId.value))?.label}`
                }
              }
              if(SENDER_OPTIONS.STORE.KEY === senderId.value && senderStoreId.value) {
                senderForwardBtnDisabled.value = false
                if(Array.isArray(senderStoreList.value)) {
                  senderDesc.value = `${(senderStoreList.value.find(el => el.key === senderStoreId.value))?.label}`
                }
              }
            }

            function focusSelectSendDate() {
              const $input: HTMLInputElement | null = document.querySelector('#select-send-date-cb32f05b-8852-4378-abdd-a382a59d7ed2 input')
              $input!.focus()
            }

            const MAX_VIDEO_SIZE_MB = 1024;

           function handleFileChange(event: Event) {  
              const fileInput = event.target as HTMLInputElement;
              const file: File | null | undefined = fileInput.files?.[0];
            
              if(file){
                if(file.type.startsWith('video/')){
                  const video = document.createElement('video');

                  video.addEventListener('loadedmetadata', function () {
                    const width = this.videoWidth;
                    const height = this.videoHeight;

                    if(width >= 1280 && height >= 720){
                      showTimeAlert('Tipo de vídeo não suportado!', 'error');
                      return;
                    }
                    processFile(file);
                  });
                  video.src = URL.createObjectURL(file);
                }else{
                  processFile(file);
                }
              }
                             
              function processFile(file : File){
                    mediaFile.value = file;
                    contentType.value = mediaFile.value.type;
                  
                    isImageFile.value = contentType.value.startsWith('image/');
                    isVideoFile.value = contentType.value.startsWith('video/');

                     if(isImageFile.value) {
                       send.value.imgToCrop = mediaFile.value
                       send.value.mediaName = mediaFile.value.name
                       blobName.value = `posts/image/${moment().valueOf()}-.${mediaFile.value.name.split(".")[1]}`
                     } else if(isVideoFile.value) {
                       totalVideoUpload.value += 1
                       send.value.media = URL.createObjectURL(mediaFile.value)
                       send.value.mediaName = mediaFile.value.name
                       blobName.value = `posts/video/${moment().valueOf()}-.${mediaFile.value.name.split(".")[1]}`
                        openModal("staticBackdrop")
                     } else {
                        showTimeAlert('Permitido apenas arquivos de vídeo ou imagem', 'error')
                      }     
              }
                     fileInput.value = '';
           } 
              
           async function handleResetMedia() {
             send.value.imgToCrop = ""
             send.value.media = ""
             send.value.mediaName = "Selecione uma mídia:"
             return
           }

          async function putPost() {
            const media: {
              currentUrl: URL | string | null
              url: URL | null,
              blobName: string | null,
            } = {
              currentUrl: null,
              url: null,
              blobName: null
            }

            if(props.postSelectedEdit && props.postSelectedEdit?.url_media) {
              try {
                media.currentUrl = props.postSelectedEdit.url_media
                media.url = new URL(props.postSelectedEdit.url_media)
                media.blobName = media.url.pathname.replace("/pontuei/", "")
              } catch (error) { }
            }

            if (mediaFile.value) {
              const options: TOptions = {
                blobHTTPHeaders: {
                  blobContentType: contentType.value
                }
              };
              try {
                if(media.blobName)
                  await deleteMediaFromClient(media.blobName)

                const { request } = (await uploadMediaFromClient(mediaFile.value, blobName.value, options))._response
                mediaFileURL.value = request.url
              } catch (error) {
                loading.value = false
                return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
              }
            }

            if (!send.value.media) {
              try {
                if(media.blobName)
                  await deleteMediaFromClient(media.blobName)
                mediaFileURL.value = ""
              } catch (error) {
                loading.value = false
                return showTimeAlert('Erro ao remover media, tente mais tarde!', 'error')
              }
            }

            try {
              loading.value = true
              const bodyToPut = {
                id: idPost.value,
                message: send.value.message,
                url_media: mediaFileURL.value.length ? mediaFileURL.value : null,
              }
              const response = await axios.put(
                '/api/putPost',
                { ...bodyToPut },
                // { headers: { 'Content-Type':'multipart/form-data' } }
              )
              await handleResetMedia()
              await resetEditorValues()
              await resetValues()
              showTimeAlert('Notificação atualizada com sucesso')
              emit('closeModalTeste')
              emit('getPosts')
              loading.value = false
            }
            catch (error) {
              if(error instanceof AxiosError) {
                showTimeAlert(error.response?.data.message, "error")
              }
            }finally {
              loading.value = false
            }
          }
     
          async function postPost() {
            loading.value = true
            
            const sendDate = config.value.date ? moment(config.value.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            const sendTime = config.value.time && moment(config.value.time, 'HH:mm:ss').isValid() ? moment(config.value.time, 'HH:mm:ss').format('HH:mm:ss') : moment().format('HH:mm:ss')
            const sendDateTime = moment(`${sendDate} ${sendTime}`, 'YYYY-MM-DD HH:mm:ss')

            if (!sendDateTime.isValid()) {
              console.error("Data e hora inválidas:", `${sendDate} ${sendTime}`);
              loading.value = false;
              return showTimeAlert('Data e hora inválidas. Por favor, verifique e tente novamente.', 'error');
            }

            const finalSendDateTime = sendDateTime

            if (mediaFile.value) {
              const options: TOptions = {
                blobHTTPHeaders: {
                  blobContentType: contentType.value
                }
              };
              try {
                const { request } = (await uploadMediaFromClient(mediaFile.value, blobName.value, options))._response
                mediaFileURL.value = request.url
              } catch (error) {
                loading.value = false
                return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
              }
            }
              
              if(!senderList.value)
                return
              const selectedOption = senderList.value[senderId.value]
              let idCompany: null | number | string = null
              let idStore: null | number | string = null

              if(selectedOption.label === SENDER_OPTIONS.COMPANY.LABEL)
                idCompany = senderCompanyId.value

              if(selectedOption.label === SENDER_OPTIONS.STORE.LABEL)
                idStore = senderStoreId.value

              const bodyToPost = {
                id_user: userStore.getUser.id,
                id_company: idCompany,
                id_store: idStore,
                message: send.value.message,
                url_media: mediaFileURL.value,
                send_date: finalSendDateTime.format('YYYY-MM-DD HH:mm:ss'),
                send_to: sendAll.value? 'Todos' : sendTo.value
              }
              try {
                const response = await axios.post(
                  '/api/postPost',
                  { ...bodyToPost },
                  { headers: { 'Content-Type':'multipart/form-data' } }
                )

                const id = entityFilter.value.map(elem => elem.company.id)

                if(sendAll.value) {
                    const sendMessage = await axios.post('/api/postPostRecipient', {
                    send_all: sendAll.value,
                    id_post: response.data.data.id,
                    jobName: idJob.value !== null ? jobName.value[idJob.value].name : null,
                  })

                  sendTo.value = ''
                }

                if(sendTo.value === 'Companhia') {
                  const sendMessage = await axios.post('/api/postPostRecipient', {
                    send_all: sendAll.value,
                    id_post: response.data.data.id,
                    jobName: idJob.value !== null ? jobName.value[idJob.value].name : null,
                    id_company: id
                  })
                }

                if(sendTo.value === 'Loja') {
                  const sendMessage = await axios.post('/api/postPostRecipient', {
                  send_all: sendAll.value,
                  id_post: response.data.data.id,
                  jobName: idJob.value !== null ? jobName.value[idJob.value].name : null,
                  id_store: id
                })
                }

                if(sendTo.value === 'Vendedor') {
                  const sendMessage = await axios.post('/api/postPostRecipient', {
                  send_all: sendAll,
                  id_post: response.data.data.id,
                  jobName: idJob.value !== null ? jobName.value[idJob.value].name : null,
                  id_competitor: id
                })
                }
                showTimeAlert('Notificação publicada com sucesso')
                emit('closeModalTeste')
                emit('getPosts')
              } catch (error) {
                if(error instanceof AxiosError) {
                  showTimeAlert(error.response?.data.message, "error")
                }
              } finally {
                loading.value = false
                idJob.value = null
                  resetValues();
                  resetEditorValues();
              }
            }

            const auxModal:any = ref(null);
            const openModal = id => { 
                  const auxElement = document.querySelector(`#${id}`);
                  auxModal.value = new Modal(auxElement);
                  auxModal.value.show();
                };

            function setImageCroped(value) {
              send.value.media = value.croppedImgBase64
              mediaFile.value = value.croppedImgFile
              openModal("staticBackdrop")
            }

            function handlerSenderList() {
              let sender = senderDesc.value;
              jobName.value = [
                {
                  'name': 'postSocial',
                  'title': '🔔 Novidade!',
                  'message': 'Novo post no Pontuei. Confira as atualizações e oportunidades exclusivas.',
                  'label': 'Postagem padrão',
                  'id': 1
                },
                {
                  'name': 'newProductSocial',
                  'title': 'Novo Produto na Loja',
                  'message': 'Um novo produto acaba de ser cadastrado! Mais uma oportunidade de venda. #BoraVender',
                  'label': 'Novo produto',
                  'id': 2
                },
                {
                  'name': 'updateAppSocial',
                  'title': 'Uma atualização está disponível',
                  'message': 'Acesse a loja de aplicativos e atualize o Pontuei pra ficar por dentro das novidades da última versão.',
                  'label': 'Nova atualização do app',
                  'id': 3
                },
                {
                  'name': 'newMessageSocial',
                  'title': 'Nova Mensagem de '+sender,
                  'message': sender+' acabou postar no Social. Veja do que se trata.',
                  'label': 'Nova mensagem',
                  'id': 4
                },
                {
                  'name': 'newTextSocial',
                  'title': '🚀 Novo Post de '+sender,
                  'message': 'Atualização no Pontuei! Fique por dentro de todas as novidades na aba Social.',
                  'label': 'Novidade no app',
                  'id': 5
                },
              ]
            }

            watch(() => isAlltrue.value, () => {
              if(isAlltrue.value){
                companies.value.forEach(elem => {
                  elem.checked = true
                })
              } else {
                companies.value.forEach(elem => {
                  elem.checked = false
                })
              }
            })

            watch(() => companies.value, () => {
              companies.value.forEach((element, index) => {
                if(element.checked) {
                  entityFilter.value = entityFilter.value.filter(elem => elem.company.id != element.company.id)
                  entityFilter.value.push(element)
                } else {
                  entityFilter.value = entityFilter.value.filter(elem => elem.company.id != element.company.id)
                }
              });
            },{ deep: true})

            watch(() => props.isChangeProps, () => {
              userLevel.value = userStore.getUser.role.level
            },{ deep: true})

            watch(() => props.postSelectedEdit?.uniqueId, () => {
              if(props.postSelectedEdit?.id) {
                idPost.value = props.postSelectedEdit?.id
                postEditMedia.value = props.postSelectedEdit?.url_media
                send.value.media = props.postSelectedEdit?.url_media
                editor.value?.commands.setContent(props.postSelectedEdit?.message)
                switNumberStep.value = 3
                senderDesc.value = props.postSelectedEdit.title
                sendTo.value = props.postSelectedEdit.count + ' ' + props.postSelectedEdit.send_to
                if(props.postSelectedEdit?.url_media) {
                  mediaFileURL.value = props.postSelectedEdit.url_media
                  try {
                    const url = new URL(props.postSelectedEdit.url_media)
                    const index = url.pathname.lastIndexOf("/")
                    send.value.mediaName = url.pathname.substring(index + 1)
                  } catch (error) { }
                }
              }
            }, { deep: true })

            watch(() => { senderId.value }, () => handleSenderList(senderId.value), { deep: true })

            watch(() => { senderDesc.value }, () => handlerSenderList, { deep: true })

            onMounted(() => hanldeSenderForwardBtn())

            watch(() => { senderId.value, senderCompanyId.value, senderStoreId.value }, () => hanldeSenderForwardBtn(), { deep: true })

            watch(() => editor.value?.getHTML(), () => {
              send.value.message = editor.value?.getHTML() || ""})

            // onBeforeMount(() => editor.value?.destroy())

            return {
              modalCompanyShow,
              selectCompany,
              companies,
              loading,
              switNumberStep,
              userLevel,
              setEntity,
              entityFilter,
              isAlltrue,
              backSwitch,
              filterDom,
              selectAll,
              config,
              send,
              postPost,
              selectStore,
              selectCompetitor,
              sendTo,
              setImageCroped,
              imgDefalt,
              auxModal,
              openModal,
              ptBr,
              disabledDateInNotification,
              senderId,
              senderList,
              senderCompanyId,
              senderCompanyList,
              senderStoreId,
              senderStoreList,
              senderForwardBtnDisabled,
              senderDesc,
              focusSelectSendDate,
              handleFileChange,
              isImageFile,
              isVideoFile,
              contentType,
              handleResetMedia,
              truncateFileName,
              userStore,
              editor,
              totalVideoUpload,
              jobName,
              idJob,
              handlerSenderList,
              idPost,
              resetValues,
              resetEditorValues,
              putPost,
              combinedDateTime
            }
        }

    })

