
import { defineComponent, watch, ref } from "vue";
import { Modal } from "bootstrap";
import { getExtensionFromFileName, getFileNameFromBlobUrl } from "@/views/Social/ListPage.vue";
import { CampFormRackSubmitBtn } from '@/components';
import {
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import useAlert from "@/composables/Alert";

export type TAlternative = {
  id?: number | string | null,
  del?: boolean,
  description: string,
  correct: boolean
}

export type TQuestion = {
  id?: number | string | null
  del?: boolean,
  title: string,
  points: number,
  alternatives: TAlternative[]
}

export function createAlternative(description = '', correct = false, del = false) {
  return <TAlternative>{
    description,
    correct,
    del
  }
}

export default defineComponent({
  name: "ModalQuiz",
  components: {
    CampFormRackSubmitBtn,
    CampTable,
    CampTableTd,
    CampTableTh
  },
  props: {
    maxAlternatives: {
      type: Number,
      default: 5
    },
    editQuestionId: {
      type: Number,
      default: -1
    },
    question: {
      type: Object as () => TQuestion,
      default: null
    },
    toggle: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const isRequiredField = ref(false)
    const header = ref("")
    const UUID = "1f8afb9d-65bb-4dfe-9428-b3ec2ec43844"
    const question = ref<TQuestion | null>(null)
    const btnCloseRef = ref<HTMLElement | null>(null)
    const isBtnAddDisabled = ref(true)
    const isBtnConfirmDisabled = ref(true)
    const questionsAmount = ref(0)
    const { showTimeAlert } = useAlert()


    // Functions
    const handleDisabledButtons = () => {
      if(!question.value)
        return

      questionsAmount.value = question.value.alternatives.filter(el => !el.del).length
      isBtnAddDisabled.value = true
      isBtnConfirmDisabled.value = true

      if(questionsAmount.value < props.maxAlternatives)
        isBtnAddDisabled.value = false

      if(questionsAmount.value > 1 && question.value.alternatives.some(el => el.correct))
        isBtnConfirmDisabled.value = false
    }

    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show()
      isRequiredField.value = false
      question.value = { ...props.question }
      question.value.alternatives = question.value.alternatives.map(el => ({ ...el, correct: el.correct ? true : false }))
      handleDisabledButtons()
      header.value = props.editQuestionId < 0 ? "Criar questão" : "Editar questão"
    }

    const addAlternative = () => {
      if(!question.value)
        return
      const length = question.value.alternatives.filter(el => !el.del).length
      if(length < props.maxAlternatives)
        question.value.alternatives.push(createAlternative())
    }

    const handleSelectionForOnlyOneItem = (id: number) => {
      if(!question.value)
        return
      question.value.alternatives = question.value.alternatives.map(el => ({ ...el, correct: false }))
      question.value.alternatives[id].correct = true
    }

    const handleDelAlternative = (j: number) => {
      if(!question.value)
        return
      isBtnAddDisabled.value = false
      question.value.alternatives = question.value.alternatives.map((el, i) => (i !== j ? { ...el } : { ...el, del: true }))
      question.value.alternatives = question.value.alternatives.filter(el => !el.del)
    }

    function onSubmit() {
      if(btnCloseRef.value && question.value) {
        if(!question.value.title.length){
          showTimeAlert("Verifique os campos obrigatórios.")
          return isRequiredField.value = true
        }
        question.value.points = +question.value.points
        if(typeof question.value.points !== 'number'){
          showTimeAlert("Verifique os campos obrigatórios.")
          return isRequiredField.value = true
        }
        if(question.value.alternatives.some(el => !el.description && !el.del))
          return isRequiredField.value = true
        btnCloseRef.value.click()
        emit("get:Question", { question: { ...question.value, del: false }, editQuestionId: props.editQuestionId});
      }
    }

    // User actions - Life Cycles
    watch(() => props.toggle, () => openModal(`modal-quiz-${UUID}`))

    watch(() => question.value?.alternatives, () => handleDisabledButtons(), { deep: true })

    return {
      header,
      UUID,
      getExtensionFromFileName,
      getFileNameFromBlobUrl,
      isRequiredField,
      question,
      addAlternative,
      handleSelectionForOnlyOneItem,
      handleDelAlternative,
      onSubmit,
      btnCloseRef,
      isBtnConfirmDisabled,
      isBtnAddDisabled
    }
  }
})
