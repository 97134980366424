
import { computed, defineComponent, PropType, ref, Ref, watch } from "vue";
import stdImgURL from "@/views/Social/Components/assets/upload-img.png"
import { CampConstrainProportionDiv } from "@/components"
import { ProductImageRack, ProductImageLoader } from "@/views/Social/Components/components"
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { campHandleFileExtension, campCheckFileSize } from "@/composables/DataValidation";
import useAlert from "@/composables/Alert";
import { Modal } from "bootstrap";

// Variables
let auxModal

// Functions
const openModal = (id: string) => {
  const auxElement = document.querySelector(`#${id}`);
  auxModal = new Modal(auxElement);
  auxModal.show();
}

function dataURLtoFile(dataURL: string, filename: string): File {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

async function createThumbnailImage(image: File, width: number, height: number): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // Redimensionar a imagem para as dimensões finais
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Obter a imagem redimensionada como um Blob
          canvas.toBlob((blob) => {
            if (blob) {
              const thumbnailFile = new File([blob], image.name, { type: image.type });
              resolve(thumbnailFile);
            } else {
              reject(new Error('A conversão para Blob falhou.'));
            }
          }, image.type);
        } else {
          reject(new Error('Não foi possível obter o contexto 2D do canvas.'));
        }
      };

      img.src = reader.result as string;
    };

    reader.readAsDataURL(image);
  });
}

export type ImgResultsType = {
  croppedImgBase64: string | null;
  croppedImgFile: File;
  thumbnailImgFile: File | null;
}
export default defineComponent({
  name: "ModalCropImage",
  components: {
    Cropper,
    CampConstrainProportionDiv,
    ProductImageRack,
    ProductImageLoader
  },
  props: {
    maxFileSizeMD: {
      type: Number,
      default: 1
    },
    productImgFile: {
      type: File as PropType<File | null>,
      required: true
    },
    sizeCanvasFixed: {
      type: Boolean,
      default: true
    },
    canvasWidth: {
      type: Number,
      default: 0
    },
    canvasHeight: {
      type: Number,
      default: 0
    },
    thumbnailWidth: {
      type: Number,
      default: 0
    },
    thumbnailHeight: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    // Variables
    const uuidModal = "b15c38a5-8543-444d-a8a5-f3e012ed52a0"
    const { showTimeAlert } = useAlert()
    const editingImgURL: Ref<string | null> = ref(null)
    const editingImgIsLoading = ref(false)
    const croppedImgBase64: Ref<string | null> = ref(null)
    const refCroppedModal: Ref<HTMLElement | null> = ref(null)
    const refBtnClose: Ref<HTMLElement | null> = ref(null)
    const auxProps = ref({
      sizeCanvasFixed: props.sizeCanvasFixed,
      width: props.canvasWidth,
      height: props.canvasHeight
    })

    // Functions
    function handleEditingImgURL(file: File) {
      editingImgURL.value = null
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        editingImgIsLoading.value = true
        editingImgURL.value = reader.result as string
      }
    }

    function handleOpenModal(file: File | null) {
      if(!file) {
        return
      }
      // if(!campHandleFileExtension(file, ["jpg", "jpeg", "png", "webp"])) {
      //   showTimeAlert("Formatos válidos: jpg, jpeg, png e webp", "error")
      //   return editingImgURL.value = null
      // }
      if(!campCheckFileSize(file, props.maxFileSizeMD)) {
        return editingImgURL.value = null
      }
      handleEditingImgURL(file)
      const element = document.getElementById(`modal-crop-image_${uuidModal}`)
      if(!element) {
        return
      }
      if (!refCroppedModal.value) {
        return
      }
      const computedStyle = getComputedStyle(refCroppedModal.value);
      const display = computedStyle.display;
      if(display == "none") {
        openModal(`modal-crop-image_${uuidModal}`)
      }
    }

    function handleImgFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if(!file) {
        return
      }
      handleEditingImgURL(file)
      fileInput.value = "";
    }

    function handleCroppedImgChange ({ coordinates, canvas }: { coordinates: any; canvas: any }) {
      editingImgIsLoading.value = false
      if (canvas) {
        croppedImgBase64.value = canvas.toDataURL("image/webp")
      }
    }

    async function handleEditionDone() {
      if (!croppedImgBase64.value) {
        return;
      }
      const croppedImgFile = dataURLtoFile(croppedImgBase64.value, "cropped_image.png");
      if(!campHandleFileExtension(croppedImgFile, ["jpg", "jpeg", "png", "webp"])) {
        showTimeAlert("Formatos válidos: jpg, jpeg, png e webp", "error")
        return editingImgURL.value = null
      }
      if(!campCheckFileSize(croppedImgFile, props.maxFileSizeMD)) {
        showTimeAlert(`Tamanho da imagem recortada ${(croppedImgFile.size / 1024**2).toFixed(1).replace(".", ",")}MB. Tamanho máximo suportado ${props.maxFileSizeMD}MB de tamanho`, "error")
        return editingImgURL.value = null
      }
      const imgResults: ImgResultsType = {
        croppedImgBase64: croppedImgBase64.value,
        croppedImgFile,
        thumbnailImgFile: null
      }
      if(props.thumbnailWidth && props.thumbnailHeight) {
        try {
          editingImgIsLoading.value = true;
          imgResults.thumbnailImgFile = await createThumbnailImage(croppedImgFile, props.thumbnailWidth, props.thumbnailHeight);
        } catch (error) {
          showTimeAlert(`Erro ao criar imagem thumb: ${error}`, "error")
        } finally {
          // refBtnClose.value && refBtnClose.value.click();
          auxModal.hide()
        }
      }
      // refBtnClose.value && refBtnClose.value.click();
      emit("getImgResults", imgResults);
    }

    function closeModalCroped() {
      auxModal.hide()
    }

    // Hooks and life cycle
    watch(() => props.productImgFile,() => handleOpenModal(props.productImgFile))

    watch(() => auxProps.value, () => {
      auxProps.value = {
        sizeCanvasFixed: props.sizeCanvasFixed,
        width: props.canvasWidth,
        height: props.canvasHeight
      }
    }, { deep: true })

    type ObjCanvasType =
      { minWidth: number, minHeight: number, maxWidth: number, maxHeight: number } |
      { width: number, height: number } |
      {}
    const objCanvas:ObjCanvasType = computed(() => {
      if(auxProps.value.sizeCanvasFixed && auxProps.value.width && auxProps.value.height) {
        return {
          width: auxProps.value.width,
          height: auxProps.value.height
        }
      } else if(auxProps.value.width && auxProps.value.height) {
        return {
          minWidth: 0,
          minHeight: 0,
          maxWidth: auxProps.value.width,
          maxHeight: auxProps.value.height
        }
      } else {
        return {}
      }
    })

    return {
      uuidModal,
      stdImgURL,
      editingImgURL,
      editingImgIsLoading,
      canvasWidth: props.canvasWidth,
      canvasHeight: props.canvasHeight,
      objCanvas,
      croppedImgBase64,
      handleImgFileChange,
      handleCroppedImgChange,
      handleEditionDone,
      refCroppedModal,
      refBtnClose,
      closeModalCroped
    };
  },
});
